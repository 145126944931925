body {
  height: 100vh;
  background-color: rgb(26, 31, 4);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#logoPicture {
  width: 15%;
  margin: 1% 5% 1% 5%;
}

#logoMtbSmall {
  width: 20%;
  margin: 1% 5% 1% 5%;
}

.responsiveOuterBox {
  background-color: rgb(44 52 60);
  border-top: 5px solid white;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 76px);
}

@media screen and (max-width: 650px) {
  .responsiveOuterBox {
    flex-direction: column;
    align-items: center;
  }
}

.responsiveCardBox {
  width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 2% 1%;
  gap: 20px;
  margin-top: 0;
  margin-bottom: 40px;
  overflow-y: auto;
}

@media screen and (max-width: 650px) {
  .responsiveCardBox {
    width: 85%;
    height: 260px;
    flex-direction: column;
    margin: 0;
  }
}

.sideBox {
  width: 80%;
  overflow: "hidden";
  margin-right: 30px;
  margin-left: 30px;
}

#cardBox {
  overflow-y: auto;
}

.map {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

.preload-img {
  position: absolute;
  width: 1px;
  top: 0;
}

.imagesContainer > div {
  position: absolute;
  inset: 0;
  overflow: hidden;
  animation-name: cf4FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 20s;
  /* z-index: -1; */
  background-size: cover;
  background-position: center;
}

@keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.first-img {
  animation-delay: 15s;
}
.second-img {
  animation-delay: 10s;
}
.third-img {
  animation-delay: 5s;
}
.fourth-img {
  animation-delay: 0;
}

.home-image {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}

.home-image-reduced {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
}

.box-a {
  position: absolute;
  /* top: 0; */
  left: 30px;
  width: 30%;
  height: calc(100vh - 68px);
  cursor: default;
  text-align: left;
}

.box-aa {
  position: relative;
  width: 94%;
  height: 100%;
  margin: 0 auto;
}

.box-aaa {
  position: absolute;
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0;
  bottom: 60px;
  left: 0;
}

.big-title {
  position: relative;
  display: block;
  color: #fff;
  font-size: 140px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 1;
  line-height: 108px;
  left: -10px;
  letter-spacing: -10px;
}

@media screen and (max-width: 1000px), (max-height: 600px) {
  .big-title {
    line-height: 66px;
    font-size: 80px;
    left: auto;
    font-weight: 300;
    letter-spacing: -8px;
  }
}

@media screen and (max-height: 450px) {
  .big-title {
    line-height: 40px;
    font-size: 50px;
    left: auto;
    font-weight: 300;
    letter-spacing: -4px;
  }
}

.under-title {
  position: relative;
  display: block;
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  z-index: 1;
  margin: 22px auto;
  text-shadow: 1px 1px 10px #0a0a0a;
}

.explore-button {
  position: relative;
  display: inline-block;
  background-color: #cdfd02;
  font-size: 15px;
  color: #0a0a0a;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 65px;
  z-index: 1;
  padding: 0 72px;
}

@media screen and (max-height: 700px) {
  .under-title {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .box-aaa {
    bottom: 15px;
  }
}

@media screen and (max-width: 1000px) {
  .explore-button {
    margin-top: 15px;
  }
}

.newContainer {
  position: absolute;
  inset: 0;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.newGalleryContainer {
  background-size: cover;
  background-position: center;
  flex-grow: 1;
}

.aboutBiggestDiv {
  height: calc(100vh - 68px);
  background-color: rgb(44 52 60);
  border-top: 5px solid white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (min-width: 800px) {
  .aboutBiggestDiv {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
}

@media screen and (min-height: 800px) and (min-width: 800px) {
  .aboutBiggestDiv {
    height: calc(100vh - 240px);
  }
}

.aboutTextDiv {
  max-width: 40%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
}

@media screen and (min-width: 800px) {
  .aboutTextDiv {
    justify-content: space-around;
  }
}
